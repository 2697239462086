import { mapGetters } from 'vuex'
export default {
	computed: {
		nextStep() {
			if(!this.qtyCart(null)) return null

			if(!this.$route.meta.hasOwnProperty('nextStep')) return null

			return this.$route.meta.nextStep
		},
		prevStep() {
			if(!this.qtyCart(null)) return null

			if(!this.$route.meta.hasOwnProperty('prevStep')) return null

			return this.$route.meta.prevStep
		},
	},


}