<script>
import { mapGetters } from 'vuex'
import stepMixin from '../mixins/stepMixin'

export default {
	mixins: [stepMixin],

	name: 'AppLayout',
	components: {
		MenuComponent:  () => import(/* webpackChunkName: "menu" */ `../components/MenuComponent`)
	},
	computed: {
		year() {
			let date = new Date()

			return date.getFullYear()
		},
		...mapGetters(['qtyCart', 'totalCart'])
	}
}
</script>

<template>
	<div class="applayout">
		<header class="header">
			<div class="header__banner">
				<div class="header__logo">
					<img src="../assets/img/logo.svg" alt="Panshito">
				</div>
			</div>
			<nav class="header__nav">
				<div class="flex-grow-1 w-100 d-none d-md-block"></div>
				<MenuComponent  class="flex-grow-1" />
				<div class="menu__aside flex-grow-1 d-flex justify-content-between" :class="{'menu__aside--controls': qtyCart() > 0}">
					<ul class="flex-grow-1 w-50 menu__social">
						<li class="menu__social-item">
							<a href="https://www.facebook.com/Panshito.sv/" class="menu__social-link">
								<span class="icon-facebook"></span>
							</a>
						</li>
						<li class="menu__social-item">
							<a href="https://www.instagram.com/panshito.sv/" class="menu__social-link">
								<span class="icon-instragram"></span>
							</a>
						</li>
					</ul>
					<div class="cart" :class="{'cart--empty': qtyCart() == 0}">
						<button class="cart__handler" @click="$router.push({name: 'Payment'})">
							<span class="cart__icon icon-hot_dog">
								<span class="path1"></span>
								<span class="path2"></span>
								<span class="path3"></span>
								<span class="path4"></span>
								<span v-if="qtyCart()" class="cart__qty">{{qtyCart()}}</span>
							</span> 
							<span class="cart__total">
								<span class="cart__total-price" :key="totalCart">
									{{totalCart | numeral('$0,0.00')}}
								</span>
								 + envio
							</span>
						</button>
					</div>
					<div class="menu__aside-control">
						<router-link :to="{name: nextStep}" class="btn btn--bold">Siguiente</router-link>
					</div>
				</div>
			</nav>
		</header>
		<main class="main" style="height:900px">
			<router-view></router-view>
		</main>
	</div>
</template>
<style lang="scss" scoped>

.inside-loader{
	position: absolute;
	left: 0;
	top: 0;
}



footer{
	color: #fff;
	background-color: #000;
	padding: 0.5rem;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}


</style>